import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, ViewChild, ViewEncapsulation } from "@angular/core";
import _ from "lodash";

import { GridSettings } from "../../_models/grid-settings.interface";
import { process, State } from "@progress/kendo-data-query";

import { GridDataResult, DataStateChangeEvent, PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";

import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { DashboardService } from "../../services/dashboard.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { Global } from "../../_constants/global.variables";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ITag } from "../../_models/tag.model";
import { IGlobal } from "../../_models/global.model";
import { UtilityService } from "projects/shared-lib/src/lib/services/utility.service";
import { ISystem } from "../../_models/system.model";
import { IAsset } from "../../_models/asset.model";
import { ISite } from "../../_models/site.model";
import { NullLogger } from "@microsoft/signalr";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "lib-gse-data",
	templateUrl: "./gse-data.component.html",
	styleUrls: ["./gse-data.component.scss"]
})
export class GSEDataComponent implements OnInit, OnDestroy {
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	eventDataRowHeight: any = 47;
	eventDataPageSize: number;
	assetTagIds: any;
	isLoading: boolean;
	timeZoneType: any;
	public global: IGlobal = Global;
	theme: string;
	assetTags: any;

	fullDataCacheSubscription: Subscription;
	signalRTagUpdateSubscription: Subscription;
	widgetIsInViewSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	dashboardTimeZoneChangedSubscription: Subscription;
	private lastUpdateTimeinMilliseconds: number;
	private requestToRunInitializeFunction: boolean = false;
	private initialLoad: boolean = true;
	private componentName: string = "gse-data: ";

	public options: any = [];
	public selected: any;
	public selectedAsset: any;
	public selectedAssetGroup: string;
	public assetGroups: any;
	public searchResults: any;
	public service: any;
	public navigationOpened: boolean = false;
	public searchText: string;

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},
		columnsConfig: [
			{
				field: "chart",
				title: "Chart",
				filterable: false,
				_width: 5,
				hidden: false
			},
			{
				field: 'Id',
				title: 'Tag Id',
				_width: 7,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: 'Name',
				title: 'Name',
				_width: 30,
				filterable: true,
			},
			{
				field: 'ShortTagName',
				title: 'Standard Name',
				_width: 15,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: 'JBTStandardObservationId',
				title: 'Std Obs Id',
				_width: 5,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: 'Severity',
				title: 'Severity',
				_width: 5,
				filterable: true,
				hidden: Global.isMobile
			},
			{
				field: 'Value',
				title: 'Value',
				_width: 10,
				filterable: true,
			},
			{
				field: 'RedisKeyName',
				title: 'Redis Key',
				filterable: true,
				_width: 15,
			},
			{
				field: 'UserDateFull',
				title: 'User Time',
				filterable: true,
				_width: 15,
				filter: 'date',
				hidden: Global.isMobile
			},
			{
				field: 'SiteDateFull',
				title: 'Site Time',
				filterable: true,
				filter: 'date',
				_width: 15,
				hidden: Global.isMobile
			},
			{
				field: 'UTCDateFull',
				title: 'UTC Time',
				filterable: true,
				filter: 'date',
				_width: 15,
			}
		]
	};

	public guid: string;

	constructor(public dataService: DataService, 
				private dashboardService: DashboardService, 
				private kendoSettingsService: KendoSettingsService, 
				private ref: ChangeDetectorRef, 
				public signalRCore: SignalRCoreService, 
				public utilityService: UtilityService
			) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.service = this;
		this.isLoading = true;
		Global.User.DebugMode && console.log(this.componentName + "this.widgetObject = %O", this.widgetObject);
		this.buildNavigationOptionsArray();
		setInterval(() => {
			this.clearRecentlyUpdatedStatus();
		}, 2000);

		setInterval(() => {
			if (this.requestToRunInitializeFunction) {
				this.initialize();
			}
		}, 1000);

		if (!_.isNil(this.widgetObject)) {
			if (Global.FullDataCacheExists === false) {
				this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.theme = Global.Theme;
						this.initialize();
						this.initialLoad = false;
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
			} else {
				this.theme = Global.Theme;
				this.initialLoad = false;
				this.initialize();
			}
		} else {
			this.isLoading = false;
			this.initialLoad = false;
			return;
		}

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				Global.User.DebugMode && console.log(this.componentName + "data: %O", data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					Global.User.DebugMode && console.log(this.componentName + "Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

					const localDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
					const siteDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
					const utcDateCol = this.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

					localDateCol.hidden = this.timeZoneType !== "User Time";
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;
				}
			});
		}
	}

	initialize() {
		this.isLoading = true;

		if (this.widgetObject?.WidgetAssetId != null) {
			this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(this.widgetObject.WidgetAssetId).subscribe((data: any) => {
				this.assetTags = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Tags;
				Global.User.DebugMode && console.log(this.componentName + "this.assetTags = %O", this.assetTags);

				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.assetTags);
				}

				this.widgetObject.isDisplayDataLive = true;
				this.finishInitializingWidget();
			});
		}
		else {
			this.requestToRunInitializeFunction = false;
			this.isLoading = false;
		}

	}

	public assetTagsOrderByDateInMilliseconds() {
		return this.assetTags.orderByDescending((a:any) => { return a.DateInMilliseconds }).toArray();
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			this.kendoSettingsService
				.saveGridSettings(
					[
						{
							gridObject: this.tagDataGrid.kendoGridParent,
							gridState: this.gridSettings.state
						}
					],
					this.widgetObject.WidgetId
				)
				.then((data: any) => {
					Global.User.DebugMode && console.log(this.componentName + "data: %O", data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	getSignalRUpdates() {
		let assetObjectInCache = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;
		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				 var activeSubject = this.dataService.returnCorrectActiveSubject(this.guid); 
			
			     Global.User.DebugMode && console.log(this.componentName + "active subjects: %O", activeSubject);
			
			
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateGridData();
				});
			});
	}

	public searchForText(searchText: any) {
		Global.User.DebugMode && console.log(this.componentName + "searchText = %O", searchText);
		this.searchText = searchText;
		if (this.searchText == "" || this.searchText == null) {
			this.assetTags =this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Tags; //-- put the list back the way it initially started.
		}
		else {
			this.signalRCore.LogActivity("Searched for '" + searchText + "' in the " + this.widgetObject.WidgetTypeName + " widget.");
			this.assetTags = this.assetTags.where((tag:ITag) => { return tag.Name.contains(searchText) || tag.JBTStandardObservation.Name.contains(searchText)})
		}
	}

	updateGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.assetTags);
		}
	}

	clearRecentlyUpdatedStatus() {
		var recentlyUpdated = this.assetTags?.where((data: any) => { return data.RecentlyUpdated == true }).toArray();
		if (recentlyUpdated != null) {
			recentlyUpdated.forEach((item: any) => {
				item.RecentlyUpdated = false;
			});

			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.assetTags);
			}
		}
	}

	onResized(event) {
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
	}

	finishInitializingWidget() {
		this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
			if (data.widgetId === this.widgetObject.WidgetId) {
				if (this.eventDataRowForCalculation !== undefined) {
					this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
				}
				// this.isWidgetInView = data.isInView;
			}
		});

		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonObjectParsed = this.kendoSettingsService.parseReturnedSettingsForDates(this.widgetObject.WidgetKendoUIJson);
			//the first item in the array is the gridSettings for the first tab of data for GSE-Overview

			let returnedParsedObject = jsonObjectParsed[0];
			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.gridSettings = this.mapGridSettings(this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.gridSettings, returnedParsedObject));
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.assetTags);
			}
			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget([this.gridSettings.columnsConfig], this.widgetObject.WidgetId);
		} else {
			if (this.tagDataGrid) {
				this.tagDataGrid.gridDataSubject.next(this.assetTags);
			}
		}
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

		this.getSignalRUpdates();
		this.requestToRunInitializeFunction = false;

		this.isLoading = false;
	}

	public mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
			gridData: this.dataService.cache !== undefined ? process(this.assetTags, state) : emptyArray
		};
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);
		this.dashboardTimeZoneChangedSubscription && this.dashboardTimeZoneChangedSubscription.unsubscribe();
		this.widgetIsInViewSubscription && this.widgetIsInViewSubscription.unsubscribe();
	}

	private buildNavigationOptionsArray() {
		var navigationWidth = Global.isMobile ? "90px" : "105px";
		this.selectedAsset = this.widgetObject?.WidgetAssetId != null ? this.dataService.cache.assetsObject[this.widgetObject?.WidgetAssetId] : null;
		this.searchResults = localStorage.getItem("searchResults");

		this.options = [
			{
				id: 1,
				width: navigationWidth,
				name: "Navigation",
				search: true,
				dataToSearch: this.dataService.cache.assets.where((asset: IAsset) => { return asset.AssetType?.AssetTypeGroupId != 1; })
															.orderBy((asset: IAsset) => { return asset.Name;	})
															.toArray(),
				selected: this.selectedAsset,
				service: this,
				action: this.changeAsset,
				widget: this.widgetObject,
				root: true,
				opened: this.selectedAsset != null ? false : true //--only displaying the initial navigation menu if no asset has been selected.
			},
			{
				id: 2,
				width: navigationWidth,
				name: "Settings",
				children: [
					{
						id: 10,
						width: navigationWidth,
						name: "Edit Name",
						action: () => this.widgetObject.editWidgetName(this.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Reload",
						action: () => this.dashboardService._reloadedWidget.next(this.widgetObject)
					},
					{
						id: 10,
						width: navigationWidth,
						name: "Delete",
						action: () => this.widgetObject.deleteFunction()
					}
				],
				root: true,
				opened: false
			}
		];

		if (Global.isMobile) {
			this.options = this.options.where((item: any) => { return item.id != 2 }).toArray(); //--removing Settings tab for mobile.
		}
		Global.User.DebugMode && console.log(this.componentName + "this.options = %O", this.options);
	}

	changeAsset(asset: any, parentWidgetObject: any, service: any) {
		console.log("this.widgetObject = %O", parentWidgetObject);
		if (!service.initialLoad) {
			parentWidgetObject.WidgetAssetId = asset?.Id;
			parentWidgetObject.WidgetSiteId = asset?.Site?.Id;
			service.selectedAsset = asset;
			service.saveWidgetObject("AssetId", asset?.Id);
			service.saveWidgetObject("SiteId", asset?.Site?.Id);
			parentWidgetObject.assetChange();
			parentWidgetObject.siteChange();
			service.requestToRunInitializeFunction = true;
		}
		else {
			service.selectedAsset = this.dataService.cache.assetsObject[parentWidgetObject.WidgetAssetId];
		}
	}

	findAsset(string: any) {

	}


	public checkNavigation(opened: any) {
		Global.User.DebugMode && console.log(this.componentName + "checkNavigation opened = %O", opened);
		this.navigationOpened = opened;
		this.isLoading = false;
	}

	public checkSelectedItem(selected: any) {
		Global.User.DebugMode && console.log(this.componentName + "checkSelectedItem invoked. selected = %O", selected);
		this.signalRCore.LogActivity("Chose '" + selected.name + "' in the navigation of the " + this.widgetObject.WidgetTypeName + " widget.");
	}

	saveWidgetObject(fieldName: string, fieldValue: string) {
		this.dataService.SQLActionAsPromise("API.Widget_UpdateRecordByIdAndFieldName " + this.widgetObject.WidgetId + ", '" + fieldName + "', '" + fieldValue + "'").then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "data updated: %O", data);
			this.signalRCore.LogActivity("Updated '" + fieldName + "' = " + fieldValue + " for the " + this.widgetObject.WidgetTypeName + " (WidgetId: " + this.widgetObject.WidgetId + ") widget.");
		});
	}
}
