import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IUser } from '../_models/user.model';

import {
	HttpClient,
	HttpHeaders,
	HttpParameterCodec,
} from '@angular/common/http';
import { UtilityService } from './utility.service';
import { tap, map, catchError, filter } from 'rxjs/operators';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { CacheUtilityService } from './cache-utility.service';
import { SignalRCoreService } from './signalr-core.service';
import { DataService } from './data.service';
import _ from 'lodash';
import * as moment from 'moment';
import { Global } from '../_constants/global.variables';
import { AnyObject } from 'chart.js/types/basic';

@Injectable()
export class DashboardService {
	public currentUserJSONObject: IUser;
	public dashboardOptionExecuted$ = new Subject<any>();
	public hiddenColumnCountChanged$ = new Subject<any>();
	public flightDataTimerTimerUpdated$ = new Subject<any>();
	public widgetInViewChanged$ = new Subject<any>();
	public cache: any;

	public _expandedDashboards: Subject<any> = new Subject();
	public expandedDashboards = this._expandedDashboards.asObservable();

	public _createdWidget: Subject<any> = new Subject();
	public createdWidget = this._createdWidget.asObservable();
	public _widgetTimeChanged: Subject<any> = new Subject();
	public widgetTimeChanged = this._widgetTimeChanged.asObservable();
	public _editedWidget: Subject<any> = new Subject();
	public editedWidget = this._editedWidget.asObservable();
	public _reloadedWidget: Subject<any> = new Subject();
	public reloadedWidget = this._reloadedWidget.asObservable();
	public _widgetNavigateToFirstTab: Subject<any> = new Subject();
	public widgetNavigateToFirstTab =
		this._widgetNavigateToFirstTab.asObservable();

	public _widgetToggleLegendHidden: Subject<any> = new Subject();
	public widgetToggleLegendHidden =
		this._widgetToggleLegendHidden.asObservable();

	public currentDashboard: any;
	public dashboardTimeScopes: any;
	public addingNewTagToChart$ = new Subject<any>();
	public screenHeight: number = window.innerHeight;
	public screenWidth: number = window.innerWidth;
	public serviceName: string = "dashboard-service: ";
	public currentDashboardId: any;
	public currentTimeZoneId: any;
	public currentTimeScopeId: any;
	public currentUpdateIntervalId: any;
	public listOfPopupsThatAreNotAutoClosedByCLickingOutside: any = [];
	public canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope: any = [];
	public fullDataCacheSubscription$: any;
	public fullDataCacheExists: boolean = false;

	constructor(
		private http: HttpClient,
		private router: Router,
		private utilityService: UtilityService,
		private signalRCore: SignalRCoreService,
		private dataService: DataService
	) {
		var currentUser = localStorage.getItem("currentUser");
		if (currentUser) {
			this.currentUserJSONObject = <IUser>(
				JSON.parse(localStorage.getItem("currentUser"))
			);
		}
		var checkUserLogin$ = new BehaviorSubject(Global.User.isLoggedIn);
		checkUserLogin$.subscribe(
			(isLoggedIn: boolean) => {
				if (isLoggedIn) {
					Global.User.DebugMode &&
						console.log(
							this.serviceName +
								"dashboards can now be loaded... user has been authenticated."
						);
				}
			},
			(err) =>
				Global.User.DebugMode &&
				console.log(`Error checking user login: ${err}`)
		);

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription$ =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.fullDataCacheExists = true;
						this.fullDataCacheSubscription$.unsubscribe();
					}
				});
		} else {
			this.fullDataCacheExists = true;
		}

	}

	closeAllOpenPopups() {
		this.listOfPopupsThatAreNotAutoClosedByCLickingOutside.forEach(
			(popup) => {
				popup.close();
			}
		);
		this.listOfPopupsThatAreNotAutoClosedByCLickingOutside = [];
	}

	loadAllExpandedDashboards(id: number) {
		var sqlStatement = "API.GetExpandedDashboardById " + id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					this.serviceName + "API.GetExpandedDashboardById = %O",
					data
				);
			this._expandedDashboards.next(
				this.SetDashboardDerivedDatesFromDayCount(data)
			);
		});
	}

	getExpandedDashboard(id: string): any {
		//-- dashboard is not currently in the expanded dashboard list, so go get it in SQL Server and add it to the list of Expanded Dashboards, then send it back to the user. --Kirk T. Sherer, February 23, 2021.
		var sqlStatement = "API.GetExpandedDashboardById " + id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			this._expandedDashboards.next(
				this.SetDashboardDerivedDatesFromDayCount(data)
			);
			return data;
		});
	}

	getDashboardTimeScopes() {
		return this.currentUserJSONObject.DashboardTimeScopes;
	}

	inViewEmitter(isInView, widgetId) {
		this.widgetInViewChanged$.next({
			isInView,
			widgetId,
		});
	}

	updatedFlightDataTimer(newValue, widgetId) {
		this.flightDataTimerTimerUpdated$.next({
			timer: newValue,
			widgetId: widgetId,
		});
	}

	executeDashboardOption(option) {
		console.log(option);
		this.dashboardOptionExecuted$.next(option);
	}

	addOrUpdateHiddenColumnCountToDashboardWidget(gridArray, widgetId) {
		var hiddenColumnArray = [];
		_.forEach(gridArray, (gridColumns) => {
			gridColumns.forEach((column) => {
				if (
					column.hidden === true &&
					column.includeInChooser === true
				) {
					hiddenColumnArray.push(column);
				}
			});
		});

		this.hiddenColumnCountChanged$.next({
			hiddenColumnArray: hiddenColumnArray,
			widgetId: widgetId,
		});
	}

	emitTimeChangeToDashboard(emitObject) {
		this._widgetTimeChanged.next(emitObject);
	}

	addOrUpdateDashboardWidgetItem(gridsterItem: any) {
		if (gridsterItem.WidgetId === "") {
			gridsterItem.WidgetId = null;
			gridsterItem.dragEnabled = true;
			gridsterItem.resizeEnabled = true;

		}
		if(gridsterItem.SelectedTabIndex === undefined){
			gridsterItem.SelectedTabIndex = null;
		}

		if (gridsterItem.AssetId === undefined) {
			gridsterItem.AssetId = null;
		}

		if (gridsterItem.WidgetName === undefined) {
			gridsterItem.WidgetName = gridsterItem.WidgetTypeName;
		}
		Global.User.DebugMode &&
			console.log("gridsterItem.AssetId = " + gridsterItem.AssetId);
		Global.User.DebugMode && console.log("gridsterItem = %O", gridsterItem);
		let statement =
			"API.DashboardAddOrUpdateWidgetRecord " +
			"@ParentDashboardId = " +
			gridsterItem.Id +
			", @Width = " +
			gridsterItem.Width +
			", @Height = " +
			gridsterItem.Height +
			", @Row = " +
			gridsterItem.Row +
			", @Col = " +
			gridsterItem.Col +
			", @Id = " +
			gridsterItem.WidgetId +
			", @WidgetTypeId = " +
			gridsterItem.WidgetTypeId +
			", @WidgetName = '" +
			gridsterItem.WidgetName +
			"', @WidgetAssetId = " +
			gridsterItem.AssetId +
			", @DragEnabled =" +
			gridsterItem.dragEnabled +
			", @ResizeEnabled = " +
			gridsterItem.resizeEnabled +
			", @SelectedTabIndex = " +
			gridsterItem.SelectedTabIndex ;

		if (!_.isNil(gridsterItem.WidgetSiteId)) {
			statement =
				statement + ", @widgetSiteId = " + gridsterItem.WidgetSiteId;
		}
		if (!_.isNil(gridsterItem.WidgetTerminalSystemId)) {
			statement =
				statement +
				", @widgetTerminalSystemId = " +
				gridsterItem.WidgetTerminalSystemId;
		}

		if (!_.isNil(gridsterItem.WidgetGateSystemId)) {
			statement =
				statement +
				", @widgetGateSystemId = " +
				gridsterItem.WidgetGateSystemId;
		}
		if (!_.isNil(gridsterItem.DataFilterJSON)) {
			statement =
				statement +
				", @DataFilterJsonString = '" +
				gridsterItem.DataFilterJSON +
				"'";
		}
		if (!_.isNil(gridsterItem.RememberSelectedTabIndex)) {
			statement =
				statement +
				", @RememberSelectedTabIndex = " +
				gridsterItem.RememberSelectedTabIndex;
		}
		if (!_.isNil(gridsterItem.VocationalSettingsJSON)) {
			statement =
				statement +
				", @VocationalSettingsJSON = '" +
				gridsterItem.VocationalSettingsJSON +
				"'";
		}
		Global.User.DebugMode &&
			console.log(this.serviceName + "SQL Statement = " + statement);
		// Id is dashboardId, WidgetId is optional, null if nothing,
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode && console.log(data);

			let returnedWidget = data.first();
			// Force Site Time when logged in as another user
			if (Global.User.isLoggedInAsDifferentUser == true) {
				returnedWidget.TimeZoneId = 2;
			}

			if (gridsterItem.WidgetId === null) {
				this._createdWidget.next(data);
			} else {
				this._editedWidget.next(data);
			}
		});
	}

	setIOPSImage(theme) {
		Global.User.DebugMode &&
			console.log(
				this.serviceName + "Setting iopsImage. theme = " + theme
			);
		this.dataService.setImagesByTheme(theme);

		Global.User.DebugMode &&
			console.log(
				this.serviceName +
					"Global.Theme = " +
					Global.Theme +
					", Global.iOPSLogoImage = " +
					Global.iOPSLogoImage +
				", Global.CompanyLogoImage = " +
				Global.CompanyLogoImage
			);
	}

	addOrUpdateDashboardRecordMobile(dashboard: any) {
		Global.User.DebugMode &&
			console.log(this.serviceName + "dashboard = %O", dashboard);

		if (!dashboard.Id) {
			dashboard.Id = null;
		}

		if (!dashboard.Ordinal) {
			dashboard.Ordinal = null;
		}

		if (!dashboard.TimeScopeId) {
			dashboard.TimeScopeId = null;
		}

		if (!dashboard.CustomStartDate) {
			dashboard.CustomStartDate = null;
		} else {
			dashboard.CustomStartDate = "'" + dashboard.CustomStartDate + "'";
		}

		if (!dashboard.CustomEndDate) {
			dashboard.CustomEndDate = null;
		} else {
			dashboard.CustomEndDate = "'" + dashboard.CustomEndDate + "'";
		}

		return this.dataService.SQLAction(
			"AddOrUpdateDashboardRecord " +
				dashboard.Id +
				", '" +
				dashboard.Name +
				"', '" +
				dashboard.Description +
				"', " +
				dashboard.Ordinal +
				", " +
				this.currentUserJSONObject.Id +
				", " +
				dashboard.CustomStartDate +
				", " +
				dashboard.CustomEndDate +
				", " +
				dashboard.TimeScopeId
		);
	}

	addOrUpdateDashboardRecord(dashboard: any) {
		Global.User.DebugMode && console.log("dashboard = %O", dashboard);

		if (!dashboard.Id) {
			dashboard.Id = null;
		}

		if (!dashboard.Ordinal) {
			dashboard.Ordinal = null;
		}

		if (!dashboard.TimeScopeId) {
			dashboard.TimeScopeId = null;
		}
		if (!dashboard.TimeZoneId) {
			dashboard.TimeZoneId = null;
		}
		if (!dashboard.UpdateIntervalId) {
			dashboard.UpdateIntervalId = null;
		}

		if (!dashboard.CustomStartDateMS) {
			dashboard.CustomStartDateMS = null;
		}

		if (!dashboard.CustomEndDateMS) {
			dashboard.CustomEndDate = null;
		}

		return (
			"AddOrUpdateDashboardRecord @Id=" +
			dashboard.Id +
			", @Name='" +
			dashboard.Name +
			"', @Description='" +
			dashboard.Description +
			"', @Ordinal=" +
			dashboard.Ordinal +
			", @CreatorUserId=" +
			this.currentUserJSONObject.Id +
			", @CustomStartDate=" +
			dashboard.CustomStartDateMS +
			", @CustomEndDate=" +
			dashboard.CustomEndDateMS +
			", @TimeScopeId=" +
			dashboard.TimeScopeId +
			", @TimeZoneId=" +
			dashboard.TimeZoneId +
			", @IsMobile=" +
			dashboard.IsMobile +
			", @DashboardTypeId=" +
			dashboard.DashboardTypeId +
			", @UpdateIntervalId=" +
			dashboard.UpdateIntervalId +
			", @GridsterTypeId=" +
			dashboard.GridsterTypeId

		);
	}

	GetExpandedDashboardById(id: number, refreshFromDB?: boolean) {
		Global.User.DebugMode &&
			console.log(
				"data.service.ts: GetExpandedDashboardById id parameter = %O",
				id
			);
		var dashboard$: any = new Observable((subscriber) => {
			var dashboardInCache = null;
			if (!refreshFromDB && this.cache != null) {
				dashboardInCache = this.cache.dashboardsObject[id.toString()];
			}

			if (dashboardInCache) {
				subscriber.next(dashboardInCache);
				subscriber.complete();
			} else {
				this.dataService
					.SQLActionAsPromise("API.GetExpandedDashboardById " + id)
					.then((data: any) => {
						var dashboard: any =
							this.SetDashboardDerivedDatesFromDayCount(data);

						if (this.cache) {
							this.cache.dashboardsObject[id.toString()] =
								dashboard;
							this.signalRCore.broadcast(
								"cache.dashboardsObject",
								dashboard
							);
						}
						subscriber.next(dashboard);
						subscriber.complete();
					});
			}
		}).pipe(
			map((data) => {
				return data;
			}),
			tap((data) => {
				Global.User.DebugMode &&
					console.log("GetExpandedDashboardById data = %O", data);
			}),
			catchError((err) => {
				throw "Error with GetExpandedDashboardById: " + err;
			})
		);

		return dashboard$;
	}

	deleteDashboardWidgetItem(widgetItem: any) {
		this.dataService
			.SQLActionAsPromise(
				"DashboardDeleteWidgetRecord " +
					widgetItem.Id +
					", " +
					widgetItem.WidgetId
			)
			.then((data: any) => {
				this.signalRCore.LogActivity("Deleted " + widgetItem.WidgetTypeName + " widget from dashboard.");

				Global.User.DebugMode && console.log(data);
			});
	}

	addTagsToExistingChart(widgetId) {
		//here we would update, then could call next.

		let tagIdsToAdd = [];
		_.forEach(this.dataService.tempTagsToChart, (TagId) => {
			tagIdsToAdd.push(TagId);
		});

		let tagIdsToAddString = tagIdsToAdd.join(",");

		let statement =
			"API.AddTagsToTagGraphByWidgetId " +
			widgetId +
			",'" +
			tagIdsToAddString +
			"'";
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.dataService.tempTagsToChart.length = 0;
			//fire event emitter which means the tags are affiliated with the widget, so now we can initialize the tagGraph. Pass the widget id so that we can evaluate if this widget id is the right one since it will be broadcasted to all instances of tagGrahph components on the screen.
			this.addingNewTagToChart$.next(widgetId);
		});
	}

	SetDashboardDerivedDatesFromDayCount(dashboard: any) {
		//debugger;
		if (dashboard && dashboard.CustomStartDate && dashboard.CustomEndDate) {
			dashboard.derivedStartDate = dashboard.CustomStartDate;
			dashboard.derivedEndDate = dashboard.CustomEndDate;

			dashboard.webApiParameterStartDate =
				this.utilityService.GetUTCQueryDate(dashboard.CustomStartDate);
			dashboard.webApiParameterEndDate =
				this.utilityService.GetUTCQueryDate(dashboard.CustomEndDate);
			dashboard.oDataFilterStartDate =
				this.utilityService.GetUTCQueryDate(dashboard.derivedStartDate);
			dashboard.oDataFilterEndDate = this.utilityService.GetUTCQueryDate(
				dashboard.derivedEndDate
			);
		} else {
			var d = new Date();
			if (dashboard && dashboard.DashboardTimeScopeDays) {
				switch (dashboard.DashboardTimeScopeDays) {
					//Special entry for "Yesterday"
					case -1:
						d.setHours(0, 0, 0, 0);

						dashboard.derivedEndDate = d;
						dashboard.derivedStartDate = new Date(
							new Date(
								new Date().setDate(d.getDate() - 1)
							).setHours(0, 0, 0, 0)
						);
						break;

					//Special entry for "Today since midnight"
					case 0:
						d.setHours(0, 0, 0, 0);
						dashboard.derivedStartDate = d;
						dashboard.derivedEndDate = new Date("1/1/2500");
						break;

					default:
						dashboard.derivedStartDate = new Date(
							new Date().setDate(
								new Date().getDate() -
									dashboard.DashboardTimeScope.Days
							)
						);
						dashboard.derivedEndDate = new Date("1/1/2500");

						break;
				}
			}
		}

		dashboard.webApiParameterStartDate =
			this.utilityService.GetUTCQueryDate(dashboard.derivedStartDate);
		dashboard.webApiParameterEndDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedEndDate
		);
		dashboard.oDataFilterStartDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedStartDate
		);
		dashboard.oDataFilterEndDate = this.utilityService.GetUTCQueryDate(
			dashboard.derivedEndDate
		);

		return dashboard;
	}

	determineIfTimeZoneIsLocal(widgetOrDataObject) {
		if (
			!_.isNil(widgetOrDataObject.TimeZoneId) &&
			widgetOrDataObject.TimeZoneId !== 0
		) {
			return widgetOrDataObject.TimeZoneId == 2 ||
				widgetOrDataObject.TimeZoneId == 3
				? false
				: true;
		} else if (
			_.isNil(widgetOrDataObject.TimeZoneId) ||
			widgetOrDataObject.TimeZoneId === 0
		) {
			if (
				!_.isNil(this.currentDashboard.TimeZoneId) &&
				this.currentDashboard.TimeZoneId !== 0
			) {
				return this.currentDashboard.TimeZoneId == 2 ||
					this.currentDashboard.TimeZoneId == 3
					? false
					: true;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	determineTimeZoneType(widgetOrDataObject) {
		if (this.fullDataCacheExists && Global.User.currentUser.DashboardTimeZones != null) {
			if (
				!_.isNil(widgetOrDataObject?.TimeZoneId) &&
				parseInt(widgetOrDataObject?.TimeZoneId) !== 0 &&
				!Number.isNaN(widgetOrDataObject?.TimeZoneId)
			) {
				let dashboardTimeZone =
					Global.User.currentUser.DashboardTimeZones.find(
						(tz) => tz.Id === parseInt(widgetOrDataObject.TimeZoneId)
					);
				return dashboardTimeZone.Description;
			} else if (
				_.isNil(widgetOrDataObject?.TimeZoneId) ||
				parseInt(widgetOrDataObject?.TimeZoneId) === 0
			) {
				let TimeZoneId;
				if (this.currentDashboard != undefined)
					TimeZoneId = this.currentDashboard.TimeZoneId;
				else TimeZoneId = 1;

				if (!_.isNil(parseInt(TimeZoneId)) && parseInt(TimeZoneId) !== 0) {
					let dashboardTimeZone =
						Global.User.currentUser.DashboardTimeZones.find(
							(tz) => tz.Id === parseInt(TimeZoneId)
						);
					return dashboardTimeZone?.Description;
				} else {
					return "";
				}
			} else if (this.currentDashboard.Name == "PunchOut Screen") {
				if (
					!_.isNil(parseInt(this.currentDashboard.TimeZoneId)) &&
					parseInt(this.currentDashboard.TimeZoneId) !== 0
				) {
					let dashboardTimeZone =
						Global.User.currentUser.DashboardTimeZones.find(
							(tz) =>
								tz.Id === parseInt(this.currentDashboard.TimeZoneId)
						);
					return dashboardTimeZone.Description;
				}
			} else {
				return "";
			}
		}

		setInterval(() => {
			this.determineIfTimeZoneIsLocal(widgetOrDataObject);
		}, 200, 5); //-- check every 200 milliseconds until the full data cache exists, then stop after five times.
	}

	determineWhatTimeScopeForWidget(widgetOrDataObject) {
		if (
			!_.isNil(widgetOrDataObject.TimeScopeId) &&
			parseInt(widgetOrDataObject.TimeScopeId) !== 0
		) {
			return Global.User.currentUser.DashboardTimeScopes.find(
				(ts) =>
					parseInt(ts.Id) === parseInt(widgetOrDataObject.TimeScopeId)
			).Description;
		} else if (
			_.isNil(widgetOrDataObject.TimeScopeId) ||
			parseInt(widgetOrDataObject.TimeScopeId) === 0
		) {
			let TimeScopeId;
			if (this.currentDashboard != undefined)
				TimeScopeId = this.currentDashboard.TimeScopeId;
			else TimeScopeId = 4;
			if (!_.isNil(TimeScopeId) && parseInt(TimeScopeId) !== 0) {
				return Global.User.currentUser.DashboardTimeScopes.find(
					(ts) => parseInt(ts.Id) === parseInt(TimeScopeId)
				).Description;
			} else {
				return "Custom";
			}
		} else {
			return "Custom";
		}
	}

	determineWhatUpdateIntervalForTacticalWidget(widgetOrDataObject) {
		if (
			!_.isNil(widgetOrDataObject.UpdateIntervalId) &&
			parseInt(widgetOrDataObject.UpdateIntervalId) !== 0
		) {
			return Global.User.currentUser.TacticalDashboardUpdateIntervals.find(
				(ts) =>
					parseInt(ts.Id) ===
					parseInt(widgetOrDataObject.UpdateIntervalId)
			).Description;
		} else if (
			_.isNil(widgetOrDataObject.UpdateIntervalId) ||
			parseInt(widgetOrDataObject.UpdateIntervalId) === 0
		) {
			if (
				!_.isNil(this.currentDashboard.UpdateIntervalId) &&
				parseInt(this.currentDashboard.UpdateIntervalId) !== 0
			) {
				return Global.User.currentUser.TacticalDashboardUpdateIntervals.find(
					(ts) =>
						parseInt(ts.Id) ===
						parseInt(this.currentDashboard.UpdateIntervalId)
				).Description;
			} else {
				return "";
			}
		} else {
			return "";
		}
	}

	evaluateAngularDirectiveNameForTimeScopeOption(
		isSingleWidgetOrListOfWidgets: string,
		item: any
	) {
		let listOfAngularDirectiveNamesForTimeScopeSelection = [
			"tagGraph",
			"gsEquipmentHoursOfUsage",
			"gsEquipmentUsage",
			"gsTopFiveAlarmTypes",
			"gsTopFiveAlarmTypesByEquipment",
			"gseTopFiveAlarmTypes",
			"gseTopFiveEquipmentAlarmTypes",
			"gseTopFiveEquipmentAlarmTypes",
			"gsPerfectHookup",
			"gsPerfectHookupOpportunity",
			"gsePeakReportData",
			"availableAssets",
			"outOfServiceAssets",
			"assetsInUse",
			"tacticalEquipmentActiveAlarms",
			"tacticalOperationalAlerts",
			"tacticalUserActiveAlarms",
			"gsOutOfService",
			"gsAssetsInUse",
			"gsAvailableAssets",
			"pbbDocking",
			"gsAlarmStatus",
			"gsCommsLoss",
			"tGSTopFive",
			"equipmentCycleCountSummary",
			"gsEquipmentNotUsedLast",
			"gseEquipmentNotUsedLast",
			"tGSETopFive",
			"tacticalGSELevels",
			"tacticalFireTruckLevels",
			"pcaNotMeetingPerformance",
			"gseSummary",
		];
		if (isSingleWidgetOrListOfWidgets === "widget") {
			if (
				listOfAngularDirectiveNamesForTimeScopeSelection.includes(
					item.AngularDirectiveName
				)
			) {
				return true;
			} else {
				return false;
			}
		}
		if (isSingleWidgetOrListOfWidgets === "list") {
			let filteredList = item.filter((widget) => {
				return listOfAngularDirectiveNamesForTimeScopeSelection.includes(
					widget.AngularDirectiveName
				);
			});
			return filteredList;
		} else if (isSingleWidgetOrListOfWidgets === "dialogPopupWidget") {
			let listOfDialogWidgetNames = ["tag-graph"];
			if (listOfDialogWidgetNames.includes(item.WidgetName)) {
				return true;
			} else {
				return false;
			}
		}
	}


	evaluateAngularDirectiveNameForTimeZoneOption(
		isSingleWidgetOrListOfWidgets: string,
		item: any
	) {
		let listOfAngularDirectiveNamesForTimeZoneSelection = [
			"siteGateSummary",
			"pbbSummary",
			"pcaSummary",
			"gpuSummary",
			"siteActiveAlarms",
			"gseActiveAlarms",
			"gseUserDefinedActiveAlarms",
			"gsTopFiveAlarmTypesByEquipment",
			"gseTopFiveAlarmTypes",
			"gseTopFiveEquipmentAlarmTypes",
			"gseSummary",
			"tagGraph",
			"wheelChairSummary",
			"centralUnitsSummary",
			"gsTopFiveAlarmTypes",
			"gsFlightSchedule",
			"gsPerfectHookup",
			"gsePeakReportData",
			"rawTagDataForAsset",
			"ahuSummary",
		];
		if (isSingleWidgetOrListOfWidgets === "widget") {
			if (
				listOfAngularDirectiveNamesForTimeZoneSelection.includes(
					item.AngularDirectiveName
				)
			) {
				return true;
			} else {
				return false;
			}
		} else if (isSingleWidgetOrListOfWidgets === "list") {
			let filteredList = item.filter((widget) => {
				return listOfAngularDirectiveNamesForTimeZoneSelection.includes(
					widget.AngularDirectiveName
				);
			});
			return filteredList;
		} else if (isSingleWidgetOrListOfWidgets === "dialogPopupWidget") {
			let listOfDialogWidgetNames = [
				"tag-graph",
				"GSE-Summary",
				"GPU-Summary",
				"AHU-Summary",
				"PBB-Summary",
				"PCA-Summary",
				"WCL-Summary",
				"CU-Summary",
				"GS-Top-Five-Alarms-Summary",
				"GS-Top-Five-Equipment-Alarms-Summary",
				"GSE-Top-Five-Equipment-Alarms-Summary",
				"GSE-Top-Five-Alarms-Summary",
			];
			if (listOfDialogWidgetNames.includes(item.WidgetName)) {
				return true;
			} else {
				return false;
			}
		}
	}

	evaluateAngularDirectiveNameForUpdateIntervalOption(
		isSingleWidgetOrListOfWidgets: string,
		item: any
	) {
		if (isSingleWidgetOrListOfWidgets === "widget") {
			let includedWidget =
				Global.User.currentUser.GenericTacticalDashboardWidgets.find(
					(gtdw) => {
						return (
							gtdw.AngularDirectiveName ===
							item.AngularDirectiveName
						);
					}
				);
			if (includedWidget !== undefined) {
				return true;
			} else {
				return false;
			}
		} else if (isSingleWidgetOrListOfWidgets === "list") {
			let filteredList = item.filter((widget) => {
				let includedWidget =
					Global.User.currentUser.GenericTacticalDashboardWidgets.find(
						(gtdw) => {
							return (
								gtdw.AngularDirectiveName ===
								widget.AngularDirectiveName
							);
						}
					);
				if (includedWidget !== undefined) {
					return includedWidget;
				}
			});
			return filteredList;
		}
		// else if (isSingleWidgetOrListOfWidgets === 'dialogPopupWidget') {
		//     let listOfDialogWidgetNames = ['tag-graph', 'GSE-Summary', 'GPU-Summary', 'PBB-Summary', 'PCA-Summary', 'WCL-Summary', 'CU-Summary', 'GS-Top-Five-Alarms-Summary', 'GS-Top-Five-Equipment-Alarms-Summary', 'GSE-Top-Five-Equipment-Alarms-Summary', 'GSE-Top-Five-Alarms-Summary']
		//     if (listOfDialogWidgetNames.includes(item.WidgetName)) {
		//         return true;
		//     } else {
		//         return false;
		//     }

		// }
	}

	determineProperDashboardUpdateInterval(widgetObject?) {
		let widgetDashboardUpdateInterval =
			Global.User.currentUser.TacticalDashboardUpdateIntervals.find(
				(updateInterval) => {
					return (
						updateInterval.Id ===
						this.currentDashboard.UpdateIntervalId
					);
				}
			);
		return widgetDashboardUpdateInterval;
	}

	determineProperTacticalDashboardUpdateIntervalForWidget(widgetObject?) {
		let widgetUpdateIntervalObject: any;

		if (widgetObject !== undefined) {
			widgetUpdateIntervalObject =
				Global.User.currentUser.TacticalDashboardUpdateIntervals.find(
					(v) => {
						return v.Id === parseInt(widgetObject.UpdateIntervalId);
					}
				);
		} else {
			widgetUpdateIntervalObject = undefined;
		}

		let dashboardUpdateIntervalObject =
			Global.User.currentUser.TacticalDashboardUpdateIntervals.find(
				(v) => {
					return (
						v.Id ===
						parseInt(this.currentDashboard.UpdateIntervalId)
					);
				}
			);

		if (widgetUpdateIntervalObject !== undefined) {
			return widgetUpdateIntervalObject;
		} else {
			return dashboardUpdateIntervalObject;
		}
	}


	determineProperTimeScopeForWidget(timeScopeConfiguration: {
		widgetObject?: any;
		UTCConfiguration?: boolean;
	}) {
		let startDateQuery = new Date();
		let endDateQuery = new Date();
		let queryStartDate: any;
		let queryEndDate: any;
		let widgetTimeScopeObject: any;
		let TimeScopeId;
		if (this.currentDashboard != undefined)
			TimeScopeId = this.currentDashboard.TimeScopeId;
		else TimeScopeId = 4;
		if (this.dashboardTimeScopes == undefined)
			this.dashboardTimeScopes =
				Global.User.currentUser.DashboardTimeScopes;

		if (!_.isNil(TimeScopeId)) {
			if (timeScopeConfiguration.widgetObject !== undefined) {
				let timeScopeConfigurationTimeScopeId;
				if (
					timeScopeConfiguration.widgetObject.TimeScopeId != undefined
				)
					timeScopeConfigurationTimeScopeId =
						timeScopeConfiguration.widgetObject.TimeScopeId;
				else timeScopeConfigurationTimeScopeId = TimeScopeId;

				widgetTimeScopeObject = this.dashboardTimeScopes.find((v) => {
					return v.Id === parseInt(timeScopeConfigurationTimeScopeId);
				});
			} else {
				widgetTimeScopeObject = undefined;
			}

			let dashboardTimeScopeObject = this.dashboardTimeScopes.find(
				(v) => {
					return v.Id === parseInt(TimeScopeId);
				}
			);

			if (
				_.isNil(widgetTimeScopeObject) &&
				_.isNil(dashboardTimeScopeObject)
			) {
				startDateQuery.setDate(startDateQuery.getDate() - 7);
				queryStartDate = timeScopeConfiguration.UTCConfiguration
					? startDateQuery.valueOf().toString()
					: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
				endDateQuery.setDate(endDateQuery.getDate());
				queryEndDate = timeScopeConfiguration.UTCConfiguration
					? endDateQuery.valueOf().toString()
					: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
			} else if (!_.isNil(widgetTimeScopeObject)) {
				if (widgetTimeScopeObject.Days == -5) {
					//Last 5 minutes
					startDateQuery.setMinutes(startDateQuery.getMinutes() - 5);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (
					widgetTimeScopeObject.Description == "Current Month"
				) {
					//Current Month
					startDateQuery.setDate(1);
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (
					widgetTimeScopeObject.Description == "Current Week"
				) {
					//Current Week
					var distance = -6;
					if (startDateQuery.getDay() != 0)
						distance = 1 - startDateQuery.getDay();
					startDateQuery.setDate(startDateQuery.getDate() + distance);
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (widgetTimeScopeObject.Days == 0) {
					//Today (Since Midnight)
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else {
					startDateQuery.setDate(
						startDateQuery.getDate() - widgetTimeScopeObject.Days
					);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				}
			} else {
				if (dashboardTimeScopeObject.Days == -5) {
					//Last 5 minutes
					startDateQuery.setMinutes(startDateQuery.getMinutes() - 5);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (
					dashboardTimeScopeObject.Description == "Current Month"
				) {
					//Current Month
					startDateQuery.setDate(1);
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (
					dashboardTimeScopeObject.Description == "Current Week"
				) {
					//Current Week
					var distance = -6;
					if (startDateQuery.getDay() != 0)
						distance = 1 - startDateQuery.getDay();
					startDateQuery.setDate(startDateQuery.getDate() + distance);
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else if (dashboardTimeScopeObject.Days == 0) {
					//Today (Since Midnight)
					startDateQuery.setHours(0, 0, 0, 0);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				} else {
					startDateQuery.setDate(
						startDateQuery.getUTCDate() -
							dashboardTimeScopeObject.Days
					);
					queryStartDate = timeScopeConfiguration.UTCConfiguration
						? startDateQuery.valueOf().toString()
						: moment(startDateQuery).format("YYYY/MM/DD HH:mm:ss");
					endDateQuery.setDate(endDateQuery.getDate());
					queryEndDate = timeScopeConfiguration.UTCConfiguration
						? endDateQuery.valueOf().toString()
						: moment(endDateQuery).format("YYYY/MM/DD HH:mm:ss");
				}
			}
		} else {
			queryStartDate = this.currentDashboard.CustomStartDateMS;
			queryEndDate = this.currentDashboard.CustomEndDateMS;
		}



		return {
			queryStartDate:
				(timeScopeConfiguration.widgetObject?.DashboardTypeId == 2 || timeScopeConfiguration.widgetObject?.AngularDirectiveName == 'gseSummary' || timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'gseOverview' || timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverview' ||timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverviewAerial'  ||timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverviewDriveline') && !_.isNil(TimeScopeId)
					? startDateQuery.getTime()
					: queryStartDate,
			queryEndDate:
				(timeScopeConfiguration.widgetObject?.DashboardTypeId == 2 || timeScopeConfiguration.widgetObject?.AngularDirectiveName == 'gseSummary' || timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'gseOverview' || timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverview' ||timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverviewAerial' || timeScopeConfiguration.widgetObject?.parentWidget?.AngularDirectiveName == 'fireTruckOverviewDriveline') && !_.isNil(TimeScopeId)
					? endDateQuery.getTime()
					: queryEndDate,
		};
	}

	calculateTimeAgo(date) {
		if (date) {
			const seconds = Math.floor((+new Date() - +new Date(date)) / 1000);
			if (seconds <= 59)
				// less than 30 seconds ago will show as 'Just now'
				return seconds + " sec ago";
			const intervals = {
				year: 31536000,
				month: 2592000,
				week: 604800,
				day: 86400,
				hour: 3600,
				minute: 60,
				second: 1,
			};
			let counter;
			for (const i in intervals) {
				counter = Math.floor(seconds / intervals[i]);
				if (counter > 0)
					if (counter === 1) {
						return counter + " " + i + " ago"; // singular (1 day ago)
					} else {
						return counter + " " + i + "s ago"; // plural (2 days ago)
					}
			}
		}
		return date;
	}

	async mapWidget(widget: any) {
		switch (widget.AngularDirectiveName) {
			case "gseOverview":
			case "mobileConditionedOverview":
			case "fuelLevel":
			case "deicerOverview":
				widget.ProperComponentName = "AssetOverviewParentComponent";
				await import(
					"projects/shared-lib/src/lib/components/asset-overview-parent/asset-overview-parent.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/asset-overview-parent/asset-overview-parent.component"
				);

				break;
			case "locateAllGSE":
				widget.ProperComponentName = "LocateAllGseWidgetComponent";
				await import(
					"projects/shared-lib/src/lib/components/locate-all-gse-widget/locate-all-gse-widget.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/locate-all-gse-widget/locate-all-gse-widget.component"
				);

				break;

			case "siteGateSummary":
				widget.ProperComponentName = "SiteAllGateSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/site-all-gate-summary/site-all-gate-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/site-all-gate-summary/site-all-gate-summary.component"
				);

				break;
			case "siteCameraView":
				widget.ProperComponentName = "SiteCameraViewComponent";
				await import(
					"projects/shared-lib/src/lib/components/site-camera-view/site-camera-view.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/site-camera-view/site-camera-view.component"
				);

				break;
			case "weather":
				widget.ProperComponentName = "WeatherWidgetComponent";
				await import(
					"projects/shared-lib/src/lib/components/weather-widget/weather-widget.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/weather-widget/weather-widget.component"
				);

				break;
			case "pbbSummary":
				widget.ProperComponentName = "PbbSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/pbb-summary/pbb-summary.component"
				);

				break;
			case "pcaSummary":
				widget.ProperComponentName = "PcaSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/pca-summary/pca-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/pca-summary/pca-summary.component"
				);

				break;
			case "gsEquipmentHoursOfUsage":
				widget.ProperComponentName =
					"GsEquipmentHoursOfOperationComponent";
				await import(
					"projects/shared-lib/src/lib/components/gs-equipment-hours-of-operation/gs-equipment-hours-of-operation.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gs-equipment-hours-of-operation/gs-equipment-hours-of-operation.component"
				);

				break;
			case "gsEquipmentUsage":
				widget.ProperComponentName =
					"GsEquipmentCycleCountSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/gs-equipment-cycle-count-summary/gs-equipment-cycle-count-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gs-equipment-cycle-count-summary/gs-equipment-cycle-count-summary.component"
				);

				break;
			case "gpuSummary":
				widget.ProperComponentName = "GpuSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gpu-summary/gpu-summary.component"
				);

				break;
			case "siteActiveAlarms":
				widget.ProperComponentName = "SiteActiveAlarmsComponent";
				await import(
					"projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/site-active-alarms/site-active-alarms.component"
				);

				break;
			case "siteActiveCommsLoss":
				widget.ProperComponentName = "SiteActiveCommsLossComponent";
				await import(
					"projects/shared-lib/src/lib/components/site-active-comms-loss/site-active-comms-loss.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/site-active-comms-loss/site-active-comms-loss.component"
				);

				break;
			case "gseActiveAlarms":
				widget.ProperComponentName = "GseActiveAlarmsComponent";
				await import(
					"projects/shared-lib/src/lib/components/gse-active-alarms/gse-active-alarms.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gse-active-alarms/gse-active-alarms.component"
				);

				break;
			case "gseUserDefinedActiveAlarms":
				widget.ProperComponentName = "GseOperationalAlarmsComponent";
				await import(
					"projects/shared-lib/src/lib/components/gse-operational-alarms/gse-operational-alarms.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gse-operational-alarms/gse-operational-alarms.component"
				);

				break;

			case "assetTracking":
				widget.ProperComponentName = "AdveezSiteComponent";
				await import(
					"projects/shared-lib/src/lib/components/adveez-site/adveez-site.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/adveez-site/adveez-site.component"
				);

				break;

			case "gseSummary":
				widget.ProperComponentName = "AssetSummaryParentComponent";
				await import(
					"projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/asset-summary-parent/asset-summary-parent.component"
				);

				break;
			case "pcaDischargePerformance":
				widget.ProperComponentName = "PcaDischargePerformanceComponent";
				await import(
					"projects/shared-lib/src/lib/components/pca-discharge-performance/pca-discharge-performance.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/pca-discharge-performance/pca-discharge-performance.component"
				);

				break;
			case "tagGraph":
			case "tagGraphSimple":
				widget.ProperComponentName = "TagGraphComponent";
				await import(
					"projects/shared-lib/src/lib/components/tag-graph/tag-graph.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/tag-graph/tag-graph.component"
				);

				break;
			case "terminalOverview":
				widget.ProperComponentName = "TerminalOverviewComponent";
				await import(
					"projects/shared-lib/src/lib/components/terminal-overview/terminal-overview.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/terminal-overview/terminal-overview.component"
				);

				break;
			case "canvas":
				widget.ProperComponentName = "CanvasComponent";
				await import(
					"projects/shared-lib/src/lib/components/canvas/canvas.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/canvas/canvas.component"
				);

				break;
			case "rawTagDataForAsset":
				widget.ProperComponentName = "RawTagDataForAssetComponent";
				await import(
					"projects/shared-lib/src/lib/components/raw-tag-data-for-asset/raw-tag-data-for-asset.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/raw-tag-data-for-asset/raw-tag-data-for-asset.component"
				);

				break;
			case "assetGeofencing":
				widget.ProperComponentName = "AssetGeofencingComponent";
				await import(
					"projects/shared-lib/src/lib/components/asset-geofencing/asset-geofencing.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/asset-geofencing/asset-geofencing.component"
				);

				break;
			case "wheelChairSummary":
				widget.ProperComponentName = "WheelchairSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/wheelchair-summary/wheelchair-summary.component"
				);

				break;
			case "wheelChairOverview":
				widget.ProperComponentName = "WheelchairOverviewComponent";
				await import(
					"projects/shared-lib/src/lib/components/wheelchair-overview/wheelchair-overview.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/wheelchair-overview/wheelchair-overview.component"
				);

				break;
			case "centralUnitsSummary":
				widget.ProperComponentName = "CentralunitsSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/centralunits-summary/centralunits-summary.component"
				);

				break;
			case "centralUnitsOverview":
				widget.ProperComponentName = "CentralunitsOverviewComponent";
				await import(
					"projects/shared-lib/src/lib/components/centralunits-overview/centralunits-overview.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/centralunits-overview/centralunits-overview.component"
				);

				break;

			case "gsPerfectHookup":
				widget.ProperComponentName = "PerfectTurnComponent";
				await import(
					"projects/shared-lib/src/lib/components/perfect-turn/perfect-turn.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/perfect-turn/perfect-turn.component"
				);

				break;
			case "gsPerfectHookupOpportunity":
				widget.ProperComponentName = "PerfectTurnOpportunityComponent";
				await import(
					"projects/shared-lib/src/lib/components/perfect-turn-opportunity/perfect-turn-opportunity.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/perfect-turn-opportunity/perfect-turn-opportunity.component"
				);

				break;
			case "gsFlightSchedule":
				widget.ProperComponentName = "FlightScheduleComponent";
				await import(
					"projects/shared-lib/src/lib/components/flight-schedule/flight-schedule.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/flight-schedule/flight-schedule.component"
				);

				break;
			case "gsePeakReportData":
				widget.ProperComponentName = "GsePeakReportsDataComponent";
				await import(
					"projects/shared-lib/src/lib/components/gse-peak-reports-data/gse-peak-reports-data.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/gse-peak-reports-data/gse-peak-reports-data.component"
				);

				break;

			case "alarmEmailSettingsForAsset":
				widget.ProperComponentName = "AlertConfigurationComponent";
				await import(
					"projects/shared-lib/src/lib/components/alert-configuration/alert-configuration.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/alert-configuration/alert-configuration.component"
				);

				break;
			case "ahuSummary":
				widget.ProperComponentName = "AhuSummaryComponent";
				await import(
					"projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/ahu-summary/ahu-summary.component"
				);

				break;
			case "testGrid":
				widget.ProperComponentName = "TestGridComponent";
				await import(
					"projects/shared-lib/src/lib/components/test-grid/test-grid.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/test-grid/test-grid.component"
				);

				break;
			case "assetInformation":
				widget.ProperComponentName = "AssetInfoViewComponent";
				await import(
					"projects/shared-lib/src/lib/components/asset-info-view/asset-info-view.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/asset-info-view/asset-info-view.component"
				);

				break;
			case "assetState":
				widget.ProperComponentName = "AssetStateViewComponent";
				await import(
					"projects/shared-lib/src/lib/components/asset-state-view/asset-state-view.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/asset-state-view/asset-state-view.component"
				);

				break;
			case "gaugeDataDisplay":
				widget.ProperComponentName = "RealtimeTagDisplayGaugeComponent";
				await import(
					"projects/shared-lib/src/lib/components/realtime-tag-display-gauge/realtime-tag-display-gauge.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/realtime-tag-display-gauge/realtime-tag-display-gauge.component"
				);
				break;
			case "singleTagValueDisplay":
				widget.ProperComponentName = "RealtimeTagDisplayComponent";
				await import(
					"projects/shared-lib/src/lib/components/realtime-tag-display/realtime-tag-display.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/realtime-tag-display/realtime-tag-display.component"
				);
				break;
			case "tagGrid":
				widget.ProperComponentName = "TagGridComponent";
				await import(
					"projects/shared-lib/src/lib/components/tag-grid/tag-grid.module"
				);
				widget.component = await import(
					"projects/shared-lib/src/lib/components/tag-grid/tag-grid.component"
				);
				break;
		}

		// widget.component = await import(`../widgets/${widget.name}/${widget.name}.component`);
	}
}
